/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import favicon16 from "../images/favicons/favicon-16x16.png";
import favicon32 from "../images/favicons/favicon-32x32.png";
import favicon96 from "../images/favicons/favicon-96x96.png";
import appleicon57 from "../images/favicons/apple-icon-57x57.png";
import appleicon60 from "../images/favicons/apple-icon-60x60.png";
import appleicon72 from "../images/favicons/apple-icon-72x72.png";
import appleicon76 from "../images/favicons/apple-icon-76x76.png";
import appleicon114 from "../images/favicons/apple-icon-114x114.png";
import appleicon120 from "../images/favicons/apple-icon-120x120.png";
import appleicon144 from "../images/favicons/apple-icon-144x144.png";
import appleicon152 from "../images/favicons/apple-icon-152x152.png";
import appleicon180 from "../images/favicons/apple-icon-180x180.png";
import msicon144 from "../images/favicons/ms-icon-144x144.png";
import androidicon192 from "../images/favicons/android-icon-192x192.png";

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            theme_color
            background_color
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const theme_color = site.siteMetadata.theme_color
  // const background_color = site.siteMetadata.background_color

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`${site.siteMetadata.title} | %s`}
      titleTemplate={`${site.siteMetadata.title} ⛰`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1.0, viewport-fit=cover`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `msapplication-TileColor`,
          content: theme_color,
        },
        {
          name: `msapplication-TileImage`,
          content: msicon144,
        },
        {
          name: `theme-color`,
          content: theme_color,
        },
      // ]}
      ].concat(meta)}
      link = {[
        { rel: 'icon', type: 'image/png', sizes: "16x16", href: `${favicon16}` },
        { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${favicon32}` },
        { rel: 'icon', type: 'image/png', sizes: "96x96", href: `${favicon96}` },
        { rel: 'icon', type: 'image/png', sizes: "192x192", href: `${androidicon192}` },
        { rel: 'apple-touch-icon', sizes: "57x57", href: `${appleicon57}` },
        { rel: 'apple-touch-icon', sizes: "60x60", href: `${appleicon60}` },
        { rel: 'apple-touch-icon', sizes: "72x72", href: `${appleicon72}` },
        { rel: 'apple-touch-icon', sizes: "76x76", href: `${appleicon76}` },
        { rel: 'apple-touch-icon', sizes: "114x114", href: `${appleicon114}` },
        { rel: 'apple-touch-icon', sizes: "120x120", href: `${appleicon120}` },
        { rel: 'apple-touch-icon', sizes: "144x144", href: `${appleicon144}` },
        { rel: 'apple-touch-icon', sizes: "152x152", href: `${appleicon152}` },
        { rel: 'apple-touch-icon', sizes: "180x180", href: `${appleicon180}` },
        // { rel: 'shortcut icon', type: 'image/png', href: `${favicon64}` }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
