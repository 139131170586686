import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
// import FFLogo from '../images/fflogo';
import FFLogo from '../images/svg/FFLogo.svg';

import headerlogoCSS from './headerlogo.module.sass'

const HeaderLogo = ({addClass}) => (
  <Link
  to="/"
    // className={addClass}
    className={headerlogoCSS.logo}
  >
    <FFLogo />
  </Link>
)
export default HeaderLogo;