// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import HeaderLogo from './HeaderLogo';
import Obfuscate from 'react-obfuscate';

import headerCSS from './header.module.sass';

const Header = ({ siteTitle }) => (
  <header
    // style={{
    //   background: `rebeccapurple`,
    //   marginBottom: `1.45rem`,
    // }}
  >
    <HeaderLogo className={headerCSS.logo} addClass="header--logo" />
    <Obfuscate
      className={headerCSS.mailto}
      email='contactus@freshfront.ca'
      headers={
        { subject: 'Email from FreshFront.ca' }
      }
    >
      Say Hello
    </Obfuscate>
    {/* <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div> */}
  </header>
)

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
